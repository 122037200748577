import apiClient from '@/services/axios'

const getAll = async () => {
  const res = await apiClient.get('/program')
  if (res) {
    return res.data
  } else {
    return []
  }
}

const getApprovedProgram = async () => {
  const res = await apiClient.get('/program/approved')
  if (res) {
    return res.data
  } else {
    return null
  }
}

const getById = async (id) => {
  const res = await apiClient.get(`/program/${id}`)
  if (res) {
    return res.data
  } else {
    return null
  }
}

const deleteById = async (id) => {
  const res = await apiClient.delete(`/program/${id}`)
  if (res) {
    return res.data
  } else {
    return null
  }
}

const create = async (data) => {
  const res = await apiClient.post('/program/dashboard', data, {})
  if (res) {
    return res
  } else {
    return null
  }
}

const continueProgram = async (data) => {
  const res = await apiClient.post('/program/continue', data, {})
  if (res) {
    return res
  } else {
    return null
  }
}

const createRedemptionProgram = async (data) => {
  const res = await apiClient.post('/program/createRedemptionProgram', data, {})
  if (res) {
    return res
  } else {
    return null
  }
}

const terminateProgram = async (data) => {
  const res = await apiClient.post('/program/terminate', data, {})
  if (res) {
    return res
  } else {
    return null
  }
}

const terminateProgramDashboard = async (data) => {
  const res = await apiClient.post('/program/terminateDashboard', data, {})
  if (res) {
    return res
  } else {
    return null
  }
}

const editById = async (id, data) => {
  const res = await apiClient.patch(`/program/${id}`, data, {})
  if (res) {
    return res
  } else {
    return null
  }
}

const getProgramTypeAll = async () => {
  const res = await apiClient.get('/programType')
  if (res) {
    return res
  } else {
    return []
  }
}

export {
  getAll,
  getById,
  getProgramTypeAll,
  deleteById,
  create,
  editById,
  getApprovedProgram,
  continueProgram,
  terminateProgram,
  terminateProgramDashboard,
  createRedemptionProgram,
}
